<template>
  <div class="cases_box">
    <!--    病例咨询-->
    <div class="cases_con posr">
      <div class="cases_con_right">
        <ul class="search_box">
          <li :class="lang === 'zh_CN' ? 'mr10' : 'mr10'" class="search_single">
            <span>{{ $t("cases.cases.hz") }}: </span>
            <el-input
              v-model="userName"
              :placeholder="$t('cases.cases.srhzm')"
              clearable
              style="width: 2rem"
            >
            </el-input>
          </li>

          <li :class="lang === 'zh_CN' ? 'mr10' : 'mr10'" class="search_single">
            <span>{{ $t("cases.cases.zxddbh") }}: </span>
            <el-input
              v-model="number"
              :placeholder="$t('cases.cases.srblh')"
              clearable
              style="width: 2rem"
            >
            </el-input>
          </li>
          <li :class="lang === 'zh_CN' ? 'mr10' : 'mr10'" class="search_single">
            <span>{{ $t("cases.cases.zt") }}: </span>
            <el-select
              v-model="status"
              :placeholder="$t('common.message.qxz')"
              clearable
              style="width: 2rem"
              @change="
                id => {
                  this.activeBtn = '1';
                  changeRouteQuery({ status: id });
                }
              "
            >
              <el-option
                v-for="item in planStatusList"
                :key="item.id"
                :label="item.label"
                :value="item.id"
                style="height: 0.4rem"
              >
              </el-option>
            </el-select>
          </li>
          <li class="search_btn_div">
            <span
              class="search_btn flex-x-y-c curp fz14 main_theme_color_333"
              @click="changeRouteQuery({ pageNum: 1 })"
              >{{ $t("common.message.cx") }}</span
            >
            <span
              class="reset_btn flex-x-y-c curp fz14 main_theme_color_333"
              @click="resetForm"
              >{{ $t("common.message.cz") }}</span
            >
          </li>
        </ul>
        <div class="operate-box">
          <div style="display: flex">
            <div
              class="btn-box"
              :class="activeBtn === '1' ? 'active-btn-box' : ''"
              @click="changeStatus('1')"
            >
              {{ $t("blzx.all") }}
            </div>
            <div
              class="btn-box"
              :class="activeBtn === '2' ? 'active-btn-box' : ''"
              @click="changeStatus('2')"
            >
              {{ $t("blzx.inService") }}
              {{ serveCount ? `(${serveCount})` : "" }}
            </div>
            <div
              class="btn-box"
              :class="activeBtn === '3' ? 'active-btn-box' : ''"
              @click="changeStatus('3')"
            >
              {{ $t("blzx.toEvaluated")
              }}{{ rateCount ? `(${rateCount})` : "" }}
            </div>
          </div>
          <div>
            <Button type="primary" @click="$router.push('/consultation/create')"
              >+ {{ $t("blzx.xjblzx") }}
            </Button>
          </div>
        </div>
        <div class="cases_list_div">
          <div>{{ $t("blzx.hzzl") }}</div>
          <div>{{ $t("blzx.consultationId") }}</div>
          <div>{{ $t("cases.cases.zt") }}</div>
          <div>{{ $t("blzx.createDate") }}</div>
          <div>{{ $t("blzx.Operation") }}</div>
        </div>
        <template v-if="casesList.length > 0" class="cases_list">
          <div
            v-for="item in casesList"
            :key="item.id"
            class="cases_single"
            @click="toDetail(item)"
          >
            <div>
              <span :title="item.userName">{{ item.userName }}</span>
              <span>{{ item.age }}{{ $t("blzx.age") }}</span>
              <span>{{
                item.sex === "1" ? $t("blzx.nn") : $t("blzx.nv")
              }}</span>
            </div>
            <div>
              <span>{{ item.number }}</span>
            </div>
            <div>
              <span>{{ statusDict[item.status] }} </span>
            </div>
            <div>
              <span>{{ item.createDatetime }}</span>
            </div>
            <div>
              <div>
                <span>{{ $t("blzx.ck") }}</span>
                <span
                  v-if="
                    ['to_submit', 'closed'].includes(item.status) ||
                      ['1', '2'].includes(item.evaluationStatus)
                  "
                  @click="removeCase(item)"
                  >{{ $t("blzx.del") }}</span
                >
              </div>
            </div>
          </div>
        </template>
        <div v-else class="no_data">
          <div class="no_data_pic"></div>
          <p class="no_data_p fz14 main_theme_color_666">
            {{ $t("home.home.zsmyblxx") }}
          </p>
        </div>
        <div
          v-show="isShowProduct"
          :class="{ select_product_dialog_news: !isShowNews }"
          class="select_product_dialog"
        >
          <div
            v-for="(item, index) in productList"
            :key="index"
            :class="{ product_item_active: productId === item.id }"
            class="product_type_name"
            @mouseover="productHoverFn(item.systemType, item.productList)"
          >
            <div class="product">{{ item.name }}</div>
            <p class="init_icon">
              <i class="el-icon-arrow-right main_theme_color"></i>
            </p>
            <div class="angle"></div>
          </div>
        </div>
        <el-pagination
          v-if="!isLoading"
          :current-page="casesForm.pageNum"
          :page-size="casesForm.pageSize"
          :page-sizes="[10, 20, 30, 50, 100]"
          :style="casesList.length < 4 ? 'margin-top: 2rem;' : ''"
          :total="caseTotal"
          background
          class="fr"
          layout="total, sizes, prev, pager, next"
          @size-change="
            changeRouteQuery({
              pageSize: arguments[0],
              pageNum: 1
            })
          "
          @current-change="
            changeRouteQuery({
              pageNum: arguments[0]
            })
          "
        >
        </el-pagination>
      </div>
    </div>
    <FullLoading v-show="isLoading" />
    <el-dialog :visible.sync="dialogTipVisible" width="500px" center>
      <div>
        <img
          style="display: block;margin: auto auto 10px;"
          src="../../common/imgs/consultation/tip.png"
          alt=""
        />
      </div>
      <p style="text-align: center">
        确定删除该病例咨询？
      </p>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogTipVisible = false">取 消</el-button>
        <el-button type="primary" @click="removeRun">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
let once = function(ele, eventName, fn) {
  let callback = function(...args) {
    ele.removeEventListener(eventName, callback);
    fn(...args);
  };
  ele.addEventListener(eventName, fn, false);
  return () => ele.removeEventListener(eventName, callback);
};
import $ from "jquery";
import { getUserId, notifyMsg, removeCreate } from "common/js/util";
import {
  casesStatistical,
  schemePage,
  schemeCount,
  schemeRemove
} from "common/api/cases";
import { getDictList } from "common/api/public";
import { mapState } from "vuex";
import { defPhoto, BOY_ICON, GIRL_ICON } from "common/js/requireImage";
import FullLoading from "components/full-loading/full-loading";
import Confirm from "components/confirm-modal/confirm";
import { extend, pickBy, map, get } from "lodash";
import { getProductTypeListByDoctor } from "common/api/public";
import { toNumber } from "../../common/js/Fn";
import { mapMutations, mapActions } from "vuex";
import ExBirdie from "../../components/expressage_birdie/index";
import { Button } from "element-ui";

export default {
  data() {
    return {
      idList: [],
      dialogTipVisible: false,
      activeBtn: "1",
      clickFn: "",
      isShowBack: false, //是否加载底部
      isLoading: false, //是否加载
      isShowConfirm: false,
      defPhoto,
      BOY_ICON,
      GIRL_ICON,
      casesLeft: [],
      casesObj: {},
      casesNextObj: {},
      initializeCasesObj: {},
      nextStatusObj: {},
      casesList: [],
      caseTotal: 0,
      isScroll: true,
      domEle: null,
      serveCount: "",
      rateCount: "",
      lang: localStorage.getItem("user_lang") || "zh_CN",
      isShowProduct: false,
      productList: [],
      productParticularList: [],
      productId: "",
      productParticularId: "",
      diaBirdie: true,
      statusList: "",
      statusDict: {
        to_submit: this.$t("blzx.toSub"),
        in_process: this.$t("blzx.inPro"),
        re_process: this.$t("blzx.inPro"),
        to_feedback: this.$t("blzx.toFeed"),
        re_feedback: this.$t("blzx.toFeed"),
        to_complete: this.$t("blzx.toCom"),
        closed: this.$t("blzx.closed")
      },
      planStatusList: [
        { label: this.$t("blzx.toSub"), id: "to_submit" },
        { label: this.$t("blzx.inPro"), id: "process" },
        { label: this.$t("blzx.toFeed"), id: "feedback" },
        { label: this.$t("blzx.toCom"), id: "to_complete" },
        { label: this.$t("blzx.closed"), id: "closed" }
      ]
    };
  },
  computed: {
    ...mapState({
      language: state => state.language
    }),
    ...mapState({
      conditionForQuery: state => state.conditionForQuery,
      isShowNews: state => state.isShowNews
    }),
    casesForm() {
      let query = this.$route.query;
      let params = {
        consultType: "scheme",
        number: this.number,
        userName: this.userName,
        submitter: getUserId(),
        pageNum: toNumber(query.pageNum, 1),
        pageSize: toNumber(query.pageSize, 10),
        evaluationStatus: this.activeBtn === "3" ? "1" : "",
        status: query.status || ""
      };
      // if (this.selectedKey === "to_confirm_plan") {
      //   params["nextStatus"] = this.selectedKey;
      // } else {
      //   params["status"] = this.selectedKey;
      // }
      return params;
    },
    status: {
      get() {
        return this.$route.query.status || "";
      },
      set(val) {
        let route = this.$route;
        this.$router.replace({
          path: route.path,
          query: extend({}, route.query, { status: val })
        });
      }
    },
    number: {
      get() {
        return this.$route.query.number || "";
      },
      set(val) {
        let route = this.$route;
        this.$router.replace({
          path: route.path,
          query: extend({}, route.query, { number: val })
        });
      }
    },
    userName: {
      get() {
        return this.$route.query.userName || "";
      },
      set(val) {
        let route = this.$route;
        this.$router.replace({
          path: route.path,
          query: extend({}, route.query, { userName: val })
        });
      }
    }
  },
  methods: {
    ...mapMutations(["HANDLE_MSG"]),
    ...mapActions({
      getMsgList: "actionGetMsgList" //病例详情
    }),
    toDetail(item) {
      if (item.status === "to_submit") {
        this.$router.push(`/consultation/create?id=${item.id}`);
      } else {
        this.$router.push(`/consultation/detail?id=${item.id}`);
      }
    },
    removeCase(item) {
      event.stopPropagation();
      this.idList = [item.id];
      this.dialogTipVisible = true;
    },
    removeRun() {
      schemeRemove({ idList: this.idList, party: "sub" }).then(() => {
        this.queryCasesList();
        this.dialogTipVisible = false;
      });
    },
    changeStatus(val) {
      this.activeBtn = val;
      this.status = "";
      this.getCount();
      const formData =
        val === "2"
          ? {
              ...this.casesForm,
              statusList: [
                "in_process",
                "re_process",
                "to_feedback",
                "re_feedback",
                "to_complete"
              ]
            }
          : this.casesForm;
      schemePage(pickBy(extend({}, formData)))
        .then(data => {
          this.isLoading = false;
          this.casesList = data.list;
          this.caseTotal = data.total;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    get,
    reload() {
      this.queryCasesList();
    },
    openDia() {
      this.$refs["exBirdie"].openDia();
    },
    //产品类型hover 事件
    productHoverFn(id, list) {
      this.productId = id;
      this.productParticularList = list;
    },
    // 新增病例按钮点击
    inquireProductList() {
      getProductTypeListByDoctor().then(data => {
        this.productList = data;
        return (this.isShowProduct = !this.isShowProduct);
      });
    },
    clickProductParticular(item) {
      let { id, cureType } = item;
      this.productParticularId = id;
      window.open(`/cases/create?productId=${id}`);
    },
    backTop() {
      $("html, body").animate({ scrollTop: 0 }, "fast");
    },
    handleScroll() {
      if (this.isScroll) {
        let height =
          document.documentElement.scrollTop || document.body.scrollTop;
        height > 700 ? (this.isShowBack = true) : (this.isShowBack = false);
      }
    },
    resetForm() {
      this.changeRouteQuery({
        type: "",
        number: "",
        userName: "",
        status: ""
      });
    },
    getCount() {
      schemeCount({
        submitter: getUserId(),
        consultType: "scheme",
        evaluationStatus: "1"
      }).then(data => {
        this.rateCount = data;
      });
      schemeCount({
        submitter: getUserId(),
        consultType: "scheme",
        statusList: [
          "in_process",
          "re_process",
          "to_feedback",
          "re_feedback",
          "to_complete"
        ]
      }).then(data => {
        this.serveCount = data;
      });
    },
    confirmHide() {
      this.isShowConfirm = false;
    },
    confirmSubmit() {
      this.removeCases();
    },
    // 左侧列表数量
    casesStatistical() {
      // let allCount = 0;
      this.casesObj = {
        ...JSON.parse(JSON.stringify(this.initializeCasesObj))
      };
      casesStatistical({
        clinicId: this.casesForm.clinicId || "",
        doctorId: getUserId() || ""
      }).then(data => {
        data.forEach(item => {
          this.casesObj[item.status]["count"] = item.count;
          // if(item.status !== 'to_handle'){
          //   allCount += +item.count;
          // }
        });
        // this.casesObj['all'].count = allCount;
      });
    },
    //病例列表
    queryCasesList(type = "0") {
      this.isLoading = true;
      if (type === "0") {
        this.casesStatistical();
      }
      const conditionForQuery =
        sessionStorage.getItem("conditionForQuery") || "";
      if (conditionForQuery) {
        this.casesForm.conditionForQuery = conditionForQuery;
      } else {
        delete this.casesForm.conditionForQuery;
      }
      const statusMap = {
        to_submit: ["to_submit"],
        process: ["in_process", "to_process"],
        feedback: ["to_feedback", "re_feedback"],
        to_complete: ["to_complete"],
        closed: ["closed"]
      };
      this.casesForm.statusList = statusMap[this.casesForm.status];
      const _params = { ...this.casesForm };
      delete _params?.status;
      this.getCount();
      schemePage(pickBy(extend({}, _params)))
        .then(data => {
          this.isLoading = false;
          this.casesList = data.list;
          this.caseTotal = data.total;
        })
        .catch(() => {
          this.isLoading = false;
        });
      sessionStorage.removeItem("conditionForQuery");
    },
    changStatus(id) {
      console.log(id);

      this.changeRouteQuery({ statusList: statusMap[id] });
    },
    changeRouteQuery(args, num = "0") {
      let route = this.$route;
      this.$router.replace({
        name: route.name,
        query: extend({}, route.query, { pageNum: 1 }, args)
      });
      this.$nextTick(() => {
        this.queryCasesList(num);
      });
    },
    getLeftTotal(arg) {
      getDictList("case.status").then(data => {
        const obj = {};
        const list = data.map(item => {
          obj[item.key] = {
            name: item.value,
            count: ""
          };
          return {
            key: item.key,
            title: item.value
          };
        });
        list.splice(2, 0, { key: "all", title: this.$t("common.message.qb") });
        this.casesLeft = list;
        this.casesObj = {
          ...JSON.parse(JSON.stringify(obj)),
          all: {
            name: this.$t("common.message.qb"),
            count: ""
          }
        };
        this.initializeCasesObj = {
          ...JSON.parse(JSON.stringify(obj)),
          all: {
            name: this.$t("common.message.qb"),
            count: ""
          }
        };
        this.queryCasesList();
      });
    }
  },
  components: {
    FullLoading, //加载中组件
    Confirm,
    ExBirdie,
    Button
  },
  watch: {
    conditionForQuery() {
      this.queryCasesList("1");
    },
    isShowProduct(val) {
      if (!val) {
        this.productId = "";
        this.productParticularId = "";
      }
    }
  },
  created() {
    this.HANDLE_MSG();
    document.title = "病例咨询";
    document.body.style.height = "auto";
    document.body.style.overflowY = "scroll";
    removeCreate();
    getDictList("case.next_status").then(data => {
      const obj = {};
      data.forEach(item => {
        obj[item.key] = item.value;
      });
      this.nextStatusObj = obj;
    });
    this.getLeftTotal();
    getDictList("case.next_status_button").then(data => {
      const obj = {};
      data.forEach(item => {
        obj[item.key] = item;
      });
      this.casesNextObj = obj;
    });
  },
  mounted() {
    sessionStorage.removeItem("casesDetail");
    window.addEventListener("scroll", this.handleScroll, false);
    this.domEle = $("body,html");

    let that = this;
    this.clickFn = once(document, "click", function(e) {
      if (
        ![
          "main_theme_color_btn main_big_btn left_create",
          "product_type_name",
          "product",
          "product_type_name product_type_name_active",
          "el-icon-arrow-right main_theme_color",
          "product_type_name product_item_active",
          "el-collapse-item__header focusing",
          "el-collapse-item is-active",
          "el-collapse-item__header is-active",
          "el-collapse product-collapse",
          "el-collapse-item__header",
          "el-collapse-item",
          "el-collapse-item__arrow el-icon-arrow-right is-active",
          "el-collapse-item__arrow el-icon-arrow-right"
        ].includes(e.target.getAttribute("class"))
      ) {
        that.isShowProduct = false;
      }
    });
  },
  beforeDestroy() {
    this.clickFn();
    sessionStorage.removeItem("conditionForQuery");
    window.removeEventListener("scroll", this.handleScroll, false);
    document.body.style.overflowY = "auto";
  }
};
</script>

<style lang="scss" scoped>
.back_top {
  width: 0.4rem;
  height: 0.4rem;
  background-color: $main_theme_color;
  color: white;
  position: fixed;
  bottom: 1rem;
  right: 0.6rem;
  cursor: pointer;
  border-radius: 0.05rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cases_con {
  width: 13.44rem;
  margin: 0 auto;
  display: flex;
  position: relative;
  top: 50px;

  .left_flex {
    position: fixed;
  }

  .select_product_dialog {
    width: 2.78rem;
    max-height: 600px;
    overflow-y: auto;
    background-color: white;
    box-shadow: 0 0 0.16rem 0 rgba(167, 167, 167, 0.35);
    position: fixed;
    top: 2.2rem;
    left: 2.6rem;
    z-index: 99;

    .product_type_name {
      width: 100%;
      height: 0.52rem;
      padding: 0.16rem 0.2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      font-size: 14px;
      color: $main_theme_color_333;

      .product {
        width: 80%;
        line-height: 0.16rem;
        margin-right: 0.2rem;
      }

      .init_icon {
        font-size: 0.14rem;
      }

      .angle {
        width: 0.18rem;
        height: 0.1rem;
        background-image: url("../../../src/common/imgs/home/top_corner_mark.png");
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        top: -0.1rem;
        left: 0.42rem;
      }
    }

    .product_type_name_active {
      background-color: #fff3cf;
      color: $main_theme_color;
    }
  }

  .select_particular_product_dialog {
    max-width: 8rem;
    min-width: 3rem;
    max-height: 600px;
    overflow-y: auto;
    background-color: white;
    box-shadow: 0 0 0.16rem 0 rgba(167, 167, 167, 0.35);
    position: fixed;
    top: 2.2rem;
    left: 5.5rem;
    z-index: 99;

    .product-collapse {
      max-height: 600px;
      overflow-y: auto;
      color: #333333 !important;

      /deep/ .el-collapse-item__header {
        flex-direction: row-reverse;
        justify-content: flex-end;
        border-bottom: none;
        padding-left: 0.16rem;
        color: #333333 !important;
        cursor: pointer !important;
        position: relative;
        background-color: transparent !important;

        span {
          font-size: 8px;
          margin-right: 8px;
        }

        .el-collapse-item__arrow {
          display: none;
        }

        &::before {
          content: "";
          width: 24px;
          height: 6px;
          border-radius: 3px;
          background-color: $main_theme_color;
          position: absolute;
          bottom: 50%;
          left: 35px;
          z-index: 1;
          opacity: 0.8;
          transform: translateY(100%);
        }

        p {
          position: relative;
          z-index: 2;
        }
      }

      /deep/ .el-collapse-item__arrow {
        margin: 0 12px 0 0;
      }

      /deep/ .el-collapse-item {
        &:last-child {
          .el-collapse-item__content {
            .product_item {
              &:last-child {
                p {
                  border-bottom: none;
                }
              }
            }
          }
        }
      }

      /deep/ .el-collapse-item__content {
        padding: 0px;

        .product_item {
          padding: 0px 0.16rem;
          border-bottom: none;

          p {
            padding: 0.16rem 0;
            height: 100%;
          }

          &:last-child {
            p {
              border-bottom: 1px solid #e5e5e5;
            }
          }
        }
      }

      /deep/ .el-collapse-item__wrap {
        border-bottom: none;
      }
    }

    .product_item {
      width: 100%;
      padding: 0.16rem 0.2rem;
      line-height: 0.18rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      cursor: pointer;
      font-size: 14px;
      color: $main_theme_color_333;

      &:hover {
        color: $main_theme_color;
        background: #f9f9f9;
      }

      .product_item_name {
        width: 100%;
        margin-right: 0.2rem;
      }

      .product_item_init {
        cursor: pointer;
      }

      .angle {
        width: 0.07rem;
        height: 0.07rem;
        background-image: url("../../../src/common/imgs/home/black_corner_mark.png");
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        right: 0;
        top: 0;
      }

      .circle_black_corner_mark {
        background-image: url("../../../src/common/imgs/home/circle_black_corner_mark.png") !important;
      }

      .active_yellow_corner_mark {
        background-image: url("../../../src/common/imgs/home/yellow_corner_mark.png") !important;
      }

      .circle_active_yellow_corner_mark {
        background-image: url("../../../src/common/imgs/home/circle_active_yellow_corner_mark.png") !important;
      }
    }

    .product_item_active {
      color: $main_theme_color;
      background-color: #f2f2f2;
    }
  }

  .left_create {
    width: 240px;
    height: 52px;
    font-size: 0.16rem;
    color: $main_theme_color_333;
    background: $main_theme_color;
    cursor: pointer;
    border-radius: 6px;
  }

  .cases_con_left {
    background-color: #fff;
    border-radius: 6px;
    width: 2.4rem;
    height: 389px;
    display: flex;
    justify-content: center;
    margin-top: 10px;

    .left_ul {
      width: 100%;
      text-align: center;

      li:nth-child(2) {
        border-bottom: 1px solid #e5e5e5;
      }

      .left_single {
        color: $main_theme_color_333;
        font-size: 0.16rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 54px;
        border-right: 0.04rem solid transparent;
        transition: all 0.3s;
        position: relative;
        cursor: pointer;

        .pending_circle {
          position: absolute;
          right: 0.67rem;
          top: 0.1rem;
        }

        &:hover {
          background: #f9f9f9;
        }

        .left_single_active {
          width: 4px;
          height: 32px;
          background: $main_theme_color;
          position: absolute;
          left: 0;
        }
      }

      .left_single_active {
        color: $main_theme_color;
      }
    }
  }

  .cases_con_right {
    width: 10.74rem;
    overflow: hidden;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding-bottom: 20px;

    .search_box {
      display: flex;
      //flex-flow: row-reverse;
      align-items: center;
      margin-bottom: 0.22rem;
      color: $main_theme_color_333;
      font-size: 0.18rem;
      position: relative;

      .search_single {
        display: flex;
        align-items: center;

        span {
          font-size: 16px;
          color: $main_theme_color_333;
          margin-right: 4px;
        }
      }

      .search_btn_div {
        display: flex;
        align-items: center;
        font-size: 0.16rem;
        margin-left: 0.4rem;

        .search_btn {
          width: 96px;
          height: 36px;
          background: $main_theme_color;
          border-radius: 4px;
          color: $main_theme_color_333;
        }

        .reset_btn {
          width: 96px;
          height: 36px;
          background: #ffffff;
          border-radius: 4px;
          border: 1px solid #bbbbbb;
          margin-left: 10px;
          color: $main_theme_color_333;
        }
      }
    }

    .operate-box {
      width: 100%;
      display: flex;
      padding-left: 5px;
      padding-right: 5px;
      margin-bottom: 20px;
      justify-content: space-between;
    }

    .cases_single {
      cursor: pointer;
      width: 100%;
      margin-bottom: 10px;
      height: 60px;
      background: #ffffff;
      border-radius: 6px;
      box-shadow: 0px 0px 8px 0px rgba(51, 51, 51, 0.12);
      display: flex;
      justify-content: space-evenly;
      text-align: center;
      align-items: center;

      > div {
        flex: 1;
        font-size: 14px;
      }

      > div:first-child {
        > span {
          margin-right: 10px;
        }

        > span:first-child {
          white-space: nowrap;
          display: inline-block;
          max-width: 100px;
          text-overflow: ellipsis;
          word-wrap: break-word;
          word-break: break-word;
          overflow: hidden;
          font-size: 16px;
          margin-right: 10px;
        }
      }

      > div:last-child {
        font-size: 16px;

        > div {
          margin-left: 90px;
          width: 140px;
          text-align: left;

          > span {
            display: inline-block;
          }

          > span:nth-child(1) {
            color: $main_theme_color;
            margin-right: 10px;
          }

          > span:nth-child(2) {
            color: orangered;
          }
        }
      }
    }

    .no_data {
      margin: 0.92rem auto 1.6rem;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .no_data_pic {
        width: 2.04rem;
        height: 1.54rem;
        background-size: 100% 100%;
        background-image: url("../../common/imgs/case/case_list_empty.png");
      }

      .no_data_p {
        color: #666666;
        font-size: 0.16rem;
        margin-top: 0.15rem;
      }
    }
  }
}

.expressage_birdie {
  display: block;
  width: 214px;
  height: 104px;
  position: fixed;
  top: 564px;
  right: 12px;
  cursor: pointer;
}

.product_item_active {
  color: $main_theme_color;
  background-color: #f2f2f2;
}

.select_product_dialog_news {
  top: 1.9rem !important;
}

.cases_list_div {
  display: flex;
  font-size: 16px;
  height: 52px;
  background-color: #fff;
  line-height: 42px;
  justify-content: space-evenly;
  margin-bottom: 20px;
  align-items: center;

  > div {
    flex: 1;
    text-align: center;
  }
}

.btn-box {
  box-sizing: border-box;
  padding: 12px 18px;
  border: 1px solid #dcdfe6;
  color: #606266;
  text-align: center;
  cursor: pointer;
  margin-right: 10px;
  background-color: #fff;
  font-size: 14px;
  border-radius: 4px;
}

.active-btn-box {
  background-color: $main_theme_color;
  color: #fff;
  border: none;
}
</style>
